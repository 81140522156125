
import { defineComponent } from "@vue/runtime-core";
import * as THREE from "three";
import { loadGLTF } from "@/services/ModelLoader";
import { MathF } from "@/helpers/MathF";
import { DirectionalLight } from "three";
import Scroller from "@/helpers/Scroller";

// ThreeJS variables need to be defined outside of the framework due to the Reactivity system of VueJS
const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(
  40,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);
let renderer: THREE.WebGLRenderer | null = null;
let canvas: null | HTMLElement = null;

const clock = new THREE.Clock();

function initThree(threeCanvas: HTMLElement) {
  canvas = threeCanvas;

  if (canvas) {
    renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true,
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(1.1);

    camera.position.z = 10;
  }
  initLights();
}

function initLights() {
  const dirLight = new DirectionalLight(0xffffff, 0.4);
  dirLight.position.set(-1, -2, 1);
  scene.add(dirLight);
  const light = new THREE.HemisphereLight(0xffffff, 0x080820, 0.8);
  light.position.y = -1;
  scene.add(light);
  const ambientLight = new THREE.AmbientLight(0x404040, 0.5); // soft white light
  scene.add(ambientLight);
}

interface model {
  url: string;
  group?: THREE.Group;
}

export default defineComponent({
  props: {
    movementSpeed: {
      default: 1
    },
    rotationSpeed: {
      default: 0.2
    }
  },
  data() {
    return {
      mouse: {
        x: 0,
        y: 0,
      } as THREE.Vector2,
      model: {
        url: "models/rocket.glb",
      } as model,
      slotStyling: ""
    };
  },
    async mounted() {
    this.mouse = new THREE.Vector2(4.4, 0);
    await this.init();
  },
  methods: {
    async init() {
      initThree(this.$refs.threeCanvas as HTMLElement);
      window.onresize = this.resizeRenderer;

      this.loadModels();
      await this.waitForTime(500)
      this.slotStyling = "opacity-100"
      await this.waitForTime(1000);
      this.render();
    },
    loadModels() {
      loadGLTF(this.model.url, (group: THREE.Group) => {
          scene.add(group);
          this.model.group = group;
          this.model.group.position.y = -10;
        });
    },
    render() {
      // Only render when the canvas is in view
      if (this.$refs.threeCanvas && Scroller.isElementInView(this.$refs.threeCanvas as HTMLElement)) {
        this.animate();
        renderer?.render(scene, camera);
      }
      requestAnimationFrame(this.render);
    },
    animate() {
        const group = this.model.group;
        if (!group) return;
        const rotSpeedMultiplier = 5;
        const distanceToMouse = MathF.clamp(
          this.caculateMouseDistance() * rotSpeedMultiplier,
          1,
          10
        );
        
        const delta = this.getDeltaTime();
        
        group.position.x =
          group.position.x - (group.position.x - this.mouse.x) * this.movementSpeed * delta;
        group.position.y =
          group.position.y - (group.position.y - this.mouse.y) * this.movementSpeed * delta;
        group.rotation.y += this.rotationSpeed * distanceToMouse * delta;

    },
    caculateMouseDistance() {
      const modelPos = this.model.group?.position;
      if (modelPos) {
        return this.mouse.distanceTo(new THREE.Vector2(modelPos.x, modelPos.y));
      }
      return 1;
    },
    resizeRenderer() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer?.setSize(window.innerWidth, window.innerHeight);
    },
    updateMousePosition(event: any) {
      const offsetTop = canvas?.offsetTop ? canvas.offsetTop : 0;
      const offsetLeft = canvas?.offsetLeft ? canvas.offsetLeft : 0;
      this.mouse.x = ((event.clientX - offsetLeft) / window.innerWidth) * 2 - 1;
      this.mouse.y =
        -((event.clientY - offsetTop) / window.innerHeight) * 2 + 1;

      this.mouse = this.unProjectVector(this.mouse);
    },
    unProjectVector(input: THREE.Vector2) {
      var vec = new THREE.Vector3();
      var pos = new THREE.Vector3();

      vec.set(input.x, input.y, 0.5);

      vec.unproject(camera);
      vec.sub(camera.position).normalize();
      var distance = -camera.position.z / vec.z;
      pos.copy(camera.position).add(vec.multiplyScalar(distance));
      return new THREE.Vector2(pos.x, pos.y);
    },
    waitForTime(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    getDeltaTime() {
      const delta = clock?.getDelta();
      if (delta) return delta;
      return 0;
    }
  },
});
