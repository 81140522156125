
import { defineComponent } from "vue";
import About from "@/components/About.vue";
import BigText from "@/components/styling/BigText.vue";
import Container from "@/components/styling/Container.vue";
import InteractiveHero from "@/components/hero/InteractiveHero.vue";
import Portfolio from "@/components/portfolio/PortfolioGallery.vue";
import RotatingText from "@/components/transition_effects/RotatingText.vue";
import SpeedTransition from "@/components/transition_effects/SpeedTransition.vue";
import FadingElement from "@/components/transition_effects/FadingElement.vue";
import Word from "@/components/transition_effects/word";

export default defineComponent({
  name: "Home",
  components: {
    About,
    BigText,
    Container,
    FadingElement,
    InteractiveHero,
    Portfolio,
    RotatingText,
    SpeedTransition,
  },
  data() {
    return {
      skillsList: [
        {
          text: "VueJS",
          style: "text-green-500 underline",
        },
        {
          text: "Angular",
          style: "text-red-500 underline",
        },
        {
          text: "Web Applications",
          style: "text-purple-500 underline",
        },
        {
          text: "Ionic",
          style: "text-blue-500 underline",
        },
        {
          text: "User Experience Design",
          style:
            "bg-clip-text bg-gradient-to-tr from-green-600 to-yellow-300 text-transparent underline",
        },
      ] as Word[],
    };
  },
});
