
import { defineComponent } from "vue";
import Word from "./word";
export default defineComponent({
  props: {
    words: {
      default: [] as Word[],
    },
    typeSpeed: {
        default: 150
    },
    showTime: {
        default: 2000
    },
    removeSpeed: {
        default: 50
    }
  },
  computed: {
      currentStyle() {
          return this.words[this.currentWord].style;
      }
  },
  data() {
    return {
      currentWord: 0,
      letterIndex: 0,
      result: "",
    };
  },
  async mounted() {
    while (this.words.length > 0) {
      for (let i = 0; i < this.words.length; i++) {
          this.currentWord = i;
        await this.typeWord(i, this.typeSpeed);
        await this.waitForTime(this.showTime);
        await this.removeResult(this.removeSpeed);
      }
    }
  },
  methods: {
    waitForTime(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async typeWord(word: number, speed = 1000) {
      for (let i = 0; i < this.words[word].text.length; i++) {
        const letter = this.words[word].text[i];
        await this.waitForTime(speed);
        this.result += letter;
      }
    },
    async removeResult(speed = 1000) {
      for (let i = this.result.length; i >= 0; i--) {
        await this.waitForTime(speed);
        this.result = this.result.slice(0, -1);
      }
    },
  },
});
