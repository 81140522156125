<template>
  <div
    class="
      max-w-4xl
      mx-auto
      px-2
      sm:px-0
      min-h-screen
      flex flex-col
      items-center
      justify-center
    "
  >
    <slot />
  </div>
</template>