
import { defineComponent } from "vue";
import { Easing } from "@/helpers/Easing";
import { MathF } from "@/helpers/MathF";
import Scroller from "@/helpers/Scroller";
export default defineComponent({
  props: {
    minScale: {
      default: 0.85,
    },
    minVisiblePercentage: {
      default: 0.7,
    },
  },
  computed: {
    isVisible() {
      return Scroller.isElementInView(this.$refs.root as HTMLElement);
    }
  },
  data() {
    return {
      opacity: 0,
      scale: 0,
    };
  },
  mounted() {
    const rootElement = this.$refs.root as HTMLElement;

    document.addEventListener("scroll", () => {
      const visibleHeight = Scroller.getVisibleHeight();

      if (Scroller.isElementInView(rootElement)) {
        let value =
          (visibleHeight - rootElement.offsetTop) / rootElement.offsetHeight;

        value /= this.minVisiblePercentage;
        value = MathF.clamp(Easing.easeInExpo(value), 0, 1);

        this.opacity = value;

        this.scale = MathF.clamp(value / 5 + this.minScale, this.minScale, 1);
      }
    });
  },
  methods: {},
});
