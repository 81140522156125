export default class Scroller {
    public static isElementInView(element: HTMLElement) {
        const domrect = element.getBoundingClientRect();
        const elementHeight = element.offsetHeight;
        const pos = domrect.y - elementHeight;

        return pos <= 0 && (pos + window.innerHeight)  >= -elementHeight;
    }

    public static getVisibleHeight() {
        return window.scrollY + window.innerHeight;
    }
}