import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
import * as THREE from "three";

export function loadGLTF(url: string, onSucces: (group: THREE.Group) => void) {
    const loader = new GLTFLoader();

    // Load a glTF resource
    loader.load(
        // resource URL
        url,
        function (gltf) {
            if (onSucces) onSucces(gltf.scene);
        },
    );
}