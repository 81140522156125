import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    style: _normalizeStyle('transform: scale(' + _ctx.scale + '); ' + 'opacity:' + _ctx.opacity),
    class: "h-full w-full"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 4))
}