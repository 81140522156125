
import { defineComponent } from "vue";
interface Border {
  style?: string;
  borderColor? :string
}
export default defineComponent({
  data() {
    return {
      borderStyle: "border-white",
      borders: [
        {
            style: "w-10/12"
        } as Border,
        {
            style: "w-7/12"
        } as Border,
        {
            style: "w-4/12"
        } as Border,
        {
            style: "w-2/12"
        } as Border,
        {
            style: "w-1/12"
        } as Border,
      ],
    };
  },
  mounted() {
    let current = 0;
    let active = true;
    setInterval(() => {
      this.borders[current].borderColor = active ? "border-red-600 bg-red-600 border-opacity-100" : "border-white border-opacity-0";
      current++;
      if (current >= this.borders.length) {
          current = 0;
          active = !active;
      } 
    }, 200);
  },
});
