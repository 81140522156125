import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-gray-900" }
const _hoisted_2 = { class: "text-xl font-bold" }
const _hoisted_3 = { class: "px-8 py-4 flex flex-col gap-y-10 bg-gray-50 rounded-b-2xl text-justify border-l border-r border-b" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass('flex flex-col py-6 px-8 border-b text-left rounded-t-2xl ' + _ctx.headerStyling + ' bg-gray-100')
    }, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.header), 1),
      _createElementVNode("h4", null, _toDisplayString(_ctx.subHeader), 1)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.buttonText && _ctx.href)
        ? (_openBlock(), _createBlock(_component_base_button, {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open(_ctx.href))),
            class: _normalizeClass(_ctx.headerStyling + ' border')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
            ]),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true)
    ])
  ]))
}