import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8ef9260"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "top-0",
  ref: "threeCanvas"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative cursor-pointer select-none",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateMousePosition && _ctx.updateMousePosition(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass('absolute h-full w-full top-0 opacity-0 transition-opacity duration-1000 ' + _ctx.slotStyling)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    _createElementVNode("canvas", _hoisted_1, null, 512)
  ]))
}