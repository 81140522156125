<template>
  <div class="">
    <big-text>Lets get together</big-text>
    <div class="flex flex-col items-center max-w-3xl">
      <div
        class="
          grid grid-cols-3
          gap-8
          rounded-xl
          shadow
          p-4
          text-gray-900
          bg-white
        "
      >
        <img
          class="rounded-xl shadow col-span-3 max-h-96 sm:col-span-1"
          src="/images/frank_hartman_linkedin_500x500.png"
        />
        <div class="text-left col-span-3 sm:col-span-2 flex flex-col gap-y-4">
          <div class="border-b border-black flex">
            <div>
              <h3 class="font-bold text-4xl">Frank Hartman</h3>
              <p>Frank@devhart.nl</p>
            </div>
          </div>
          <ul class="list-disc list-inside">
            <li>Front-End development</li>
            <li>App Development</li>
            <li>User Experience design</li>
          </ul>
          <div class="flex gap-2 items-center text-blue-700 hover:text-blue-500">
            <svg
              class="w-5 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
              />
            </svg>
            <a href="https://linkedin.com" class="underline"> LinkedIn </a>
          </div>
        </div>
      </div>
      <div class="pt-10">
      <p>Want to get in contact with a project that you are working on?</p>
      <p>Contact me on LinkedIn or send an email to frank@devhart.nl</p>
      </div>
    </div>
  </div>
</template>

<script>
import BigText from "@/components/styling/BigText.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    BigText,
  },
});
</script>

<style>
</style>