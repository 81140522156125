<template>
  <div class="fixed z-50 flex right-20 top-4 gap-x-4 text-white">
    <a href="#portfolio"> portfolio </a>|
    <a href="#contact"> contact </a>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({

});
</script>

<style>

</style>