
import { defineComponent } from "vue";
import BaseButton from "@/components/styling/BaseButton.vue";
export default defineComponent({
  components: {
    BaseButton,
  },
  props: {
    header: {
      default: "Header",
    },
    headerStyling: {
      default: ""
    },
    subHeader: {
      default: "",
    },
    description: {
      default: "",
    },
    buttonText: {
      default: "",
    },
    href: {
      default: "",
    },
  },
  methods: {
    open(url: string) {
      window.open(url);
    },
  },
});
